.tabs {
    visibility: visible;
    width: auto;
    height: 100%;
    font-size: 1.5em;
}

.menu {
    position: absolute;
    width: 100%;
    height: 30%;
    top: 70%;
    background-color: rgb(217, 217, 217);
    text-align: left;
    display: flex;
}

.dummy {
    background-color: red;
}

.btn {
    align-items: center;
    background-clip: padding-box;
    background-color: rgb(66, 185, 245);
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 600;
    font-size: inherit;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    position: relative;
    text-decoration: none;
    transition: all 250ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
}

.btn[disabled],
.btn:disabled {
    background-color: rgb(141, 141, 141) !important;
}

.btn:hover {
    background-color: rgb(99, 198, 247);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

.btn:active {
    background-color: rgb(80, 159, 199);
}

.tab {
    width: 100%;
    height: 33.33%;
}

.submit {
    width: 5em;
    height: 4vh;
}

.selected {
    background-color: rgb(0, 214, 157);
    align-items: center;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    display: inline-flex;
    font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 600;
    font-size: inherit;
    justify-content: center;
    line-height: 1.25;
    margin: 0;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: baseline;
}

.right {
    flex: auto;
    width: 150%;
    padding: 10px 25px;
    box-sizing: border-box;
    overflow-y: auto;
}

/* Act */

.act {
    height: 100%;
}

.text-input {
    width: 100%;
    height: 50%;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    display: block;
    padding: 10px;
}

/* Item */
.item {
    background-color: rgb(184, 184, 184);
    align-items: center;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: .25rem;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
    box-sizing: border-box;
    color: #fff;
    display: inline-flex;
    /* font-size: 20px; */
    line-height: 1.25;
    margin: 0;
    padding: 5px 15px;
    position: relative;
    width: fit-content;
}

.inventory {
    height: 100%;
    /* overflow-y: auto; */
}

.inventory-container {
    flex-wrap: wrap;
    display: flex;
}


/* Story */
.story {
    width: 100%;
    background-color: aquamarine;
}

/* Stat */

.stats {
    height: 100%;
    /* overflow-y: auto; */
    /* display: flex; */
}

.stat-container {
    flex-wrap: wrap;
    display: flex;
}

.skill-container {
    flex-wrap: wrap;
    display: flex;
}

.stat-title {
    display: block;
}

.skill-title {
    display: block;
}
