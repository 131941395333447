/* .entities {
    position: absolute;
    top: 20vh;
    left: 0;
    display:flex;
} */

.entity-right {
    position: absolute;
    top: 20vh;
    right: 5vw;
    display: flex;
    flex-direction: column;
}

.entity-left {
    position: absolute;
    top: 20vh;
    left: 5vw;
}

.health {
    width: 400px;
    height: 20px;
}

progress[value] {
    --w: 200px; /* the width*/
    --color:  /* the progress color */
      /* if < 30% "red" */
      linear-gradient(red    0 0) 0 /calc(var(--w)*.3 - 100%) 1px,
      /* if < 60% "orange" */
      linear-gradient(orange 0 0) 0 /calc(var(--w)*.6 - 100%) 1px,
      /* else "green" */
      green;
    --background: lightgrey; /* the background color */
  
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: var(--w);
    margin: 0 10px;
    border-radius: 10em;
    background: var(--background);
}

progress[value]::-webkit-progress-bar {
    border-radius: 10em;
    background: var(--background);
}

progress[value]::-webkit-progress-value {
    border-radius: 10em;
    background: var(--color);
}

progress[value]::-moz-progress-bar {
    border-radius: 10em;
    background: var(--color);
}
